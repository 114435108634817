var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("glb-login", {
    staticClass: "custom-login",
    attrs: {
      platIconTitleData: _vm.platIconTitleData,
      openRegister: _vm.openRegister,
      rightTopLogoUrl: "",
      isLoginLoading: _vm.isLoginLoading,
      openSmsLogin: _vm.openSmsLogin,
      sendSmsFlag: _vm.sendSmsFlag,
    },
    on: {
      pwdLogin: _vm.handlePwdLogin,
      smsLogin: _vm.handleSmsLogin,
      register: _vm.handleRegister,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }